import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Footer from './view/common/Footer';
import Header from './view/common/Header';
import Home from './view/Home';
import Edit from './view/page/edit';
import Init from './view/setting/init';
import Setting from './view/setting/setting';

export default function App() {
	return (
		<BrowserRouter basename='/'>
			<Header />
			<main className='document__main pt-0' role="main">
				<div className='container h-100 mt-0'>
					<div className='row h-100 mt-0'>
						<div className='col-12 h-100 mt-0'>
							<Routes>
								<Route path='/'>
									<Route index element={<Home />} />
									<Route path='init' element={<Init />} />
									<Route path='page/:id' element={<Edit />} />
									<Route path='setting' element={<Setting />} />
								</Route>
							</Routes>
						</div>
					</div>
				</div>
			</main>
			<Footer />
		</BrowserRouter>
	);
}
