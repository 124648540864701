import { useEffect, useRef, useState } from "react";
import { Accordion, Button, Form, InputGroup, Table } from "react-bootstrap";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import Crumb from "../../controller/Crumb";
import SendJson from "../../controller/SendJson";
import { createKey } from "../../controller/keys";

const HeaderItem={
    meta:'メタ',
    link:"リンク",
    script:"スクリプト"
};
const date=new Date();
export default function Setting(){
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    const [Template,setTemplate]=useState([]);
    const [Alert,setAlert]=useState();

    const [HeaderMeta,setHeaderMeta]=useState([]);
    const [HeaderLink,setHeaderLink]=useState([]);
    const [HeaderScript,setHeaderScript]=useState([]);
    const [FooterScript,setFooterScript]=useState([]);

    const template=useRef();
    const SiteName=useRef();
    const SiteNameVisible=useRef();
    const CopyRight=useRef();
    const CopyRightVisible=useRef();
    
    useEffect(()=>{
        let send={
            url:'v1/lp/setting/template',
            method:'GET'
        };
        Fetch(send).then(data=>{
            if(data['result']){
                setTemplate(data['payloads']);
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            setAlert({'type':'danger','style':true,'msgs':["通信エラーが発生しました。"]});
            console.log(error);
        })
        getSetting();
    },[])
    function getSetting(){
        setVisible(true);
        let send={
            url:'/v1/lp/setting',
            method:'GET'
        };
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data['payloads']);
                setHeaderLink(data['payloads']['headers']['links'])
                setHeaderMeta(data['payloads']['headers']['metas'])
                setHeaderScript(data['payloads']['headers']['scripts'])
                setFooterScript(data['payloads']['footers']['scripts'])
            }else{
                setAlert({'type':'danger','style':true,'description':data['error']['description']});
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            setAlert({'type':'danger','style':true,'msgs':["通信エラーが発生しました。"]});
            console.log(error);
        })
    }

    function addHeaderMetaAttr(e){
        let newAttr=HeaderMeta.concat();
        newAttr[e.target.dataset.index]=newAttr[e.target.dataset.index].concat({
            attribute:"",
            value:""
        })
        setHeaderMeta(newAttr);
    }
    function addHeaderLinkAttr(e){
        let newAttr=HeaderLink.concat();
        newAttr[e.target.dataset.index]=newAttr[e.target.dataset.index].concat({
            attribute:"",
            value:""
        })
        setHeaderLink(newAttr);
    }
    function addHeaderScriptAttr(e){
        let newAttr=HeaderScript.concat();
        newAttr[e.target.dataset.index]=newAttr[e.target.dataset.index].concat({
            attribute:"",
            value:""
        })
        setHeaderScript(newAttr);
    }
    function addFooterScriptAttr(e){
        let newAttr=FooterScript.concat();
        newAttr[e.target.dataset.index]=newAttr[e.target.dataset.index].concat({
            attribute:"",
            value:""
        })
        setFooterScript(newAttr);
    }
    function addHeaderMeta(){
        let newAttr=HeaderMeta.concat();
        newAttr=newAttr.concat([[]]);
        setHeaderMeta(newAttr);
    }
    function addHeaderLink(){
        let newAttr=HeaderLink.concat();
        newAttr=newAttr.concat([[]]);
        setHeaderLink(newAttr);
    }
    function addHeaderScript(){
        let newAttr=HeaderScript.concat();
        newAttr=newAttr.concat([[]]);
        setHeaderScript(newAttr);
    }
    function addFooterScript(){
        let newAttr=FooterScript.concat();
        newAttr=newAttr.concat([[]]);
        setFooterScript(newAttr);
    }
    function changeHeaderMeta(e){
        let newAttr=HeaderMeta.concat();
        newAttr[e.target.dataset.index][e.target.dataset.idx][e.target.dataset.type]=e.target.value;
        setHeaderMeta(newAttr);
    }
    function changeHeaderLink(e){
        let newAttr=HeaderLink.concat();
        newAttr[e.target.dataset.index][e.target.dataset.idx][e.target.dataset.type]=e.target.value;
        setHeaderLink(newAttr);
    }
    function changeHeaderScript(e){
        let newAttr=HeaderScript.concat();
        newAttr[e.target.dataset.index][e.target.dataset.idx][e.target.dataset.type]=e.target.value;
        setHeaderScript(newAttr);
    }
    function changeFooterScript(e){
        let newAttr=FooterScript.concat();
        newAttr[e.target.dataset.index][e.target.dataset.idx][e.target.dataset.type]=e.target.value;
        setFooterScript(newAttr);
    }

    function doEntry(){
        setVisible(true);
        var {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
        let AllError=[].concat(error);
        let AllBody=Object.assign({
            headers:{},
            header:{menus:{visible:false}},
            footers:{}
        },body);
        //headers
        AllBody['headers']['metas']=HeaderMeta;
        AllBody['headers']['links']=HeaderLink;
        AllBody['headers']['scripts']=HeaderScript;
        //header
        AllBody['header']['logos']={
            type:'TEXT',
            text:SiteName.current.value,
            visible:SiteNameVisible.current.checked
        };
        //footer
        AllBody['footers']['scripts']=FooterScript;
        AllError=AllError.concat(error);
        AllBody['footers']['copyrights']={
            type:'TEXT',
            text:CopyRight.current.value,
            visible:CopyRightVisible.current.checked
        };
        window.scroll({top:0});
        if(AllError.length){
            setAlert({'type':'danger','style':true,'msgs':AllError});
            setVisible(false);
            return;
        }else{
            let send={
                url:"v1/lp/setting",
                method:'POST',
                body:AllBody
            };
            Fetch(send).then(data=>{
                if(data['result']){
                    getSetting();
                    setAlert({'type':'success','style':true,'msgs':['設定を登録しました。']});
                }else{
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
                setVisible(false);
            }).catch(error=>{
                setVisible(false);
                setAlert({'type':'danger','style':true,'msgs':["通信エラーが発生しました。"]});
                console.log(error);
            });
        }
    }
    function openSamplePage(){
        window.open('https://lp.canteach.jp/template/template-'+template.current.value,'_blank');
    }
    return (
        <div>
            {Item? <Crumb CurrentText={"設定"} items={[{index:0,href:'/',text:'ホーム'}]} />:""}
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{(Item && Template.length) ?
            <div>
                <h3>
                    サイト設定
                </h3>
                <div className="my-2">
                    <Form.Group>
                        <Form.Label>
                            テンプレート
                        </Form.Label>
                        <InputGroup>
                            <Form.Select defaultValue={Item['templates']['TemplateId']} name="TemplateId" className="js-data" ref={template} required>{Template.map(template=>
                                <option key={template['TemplateId']} value={template['TemplateId']}>{template['TemplateName']}</option>
                                )}
                            </Form.Select>
                            <Button variant="secondary" type="button" onClick={openSamplePage}>サンプルページ表示</Button>
                        </InputGroup>
                    </Form.Group>
                </div>
                <div className="my-2">
                    <h4>
                        サイト名
                    </h4>
                    <Form.Group>
                        <Form.Control type="text" defaultValue={Item['header']['logos']['text']} placeholder="サイト名" ref={SiteName} />
                        <Form.Check type="checkbox" id="SiteNameVisible" label="サイト名を表示する" defaultChecked={Item['header']['logos']['visible']} ref={SiteNameVisible} />
                    </Form.Group>
                </div>
                <div className="my-2">
                    <h4>
                        ヘッダー
                    </h4>
                    <Accordion defaultActiveKey={0}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                メタ情報
                            </Accordion.Header>
                            <Accordion.Body>
                                <Button variant="success" onClick={addHeaderMeta} className="mb-2">追加</Button>
                                <Accordion defaultActiveKey={0}>{HeaderMeta.map((tag,index)=>
                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header>
                                            &lt;meta {tag.map(attr=>attr['attribute'] && `${attr['attribute']}="${attr['value']}" `)}/&gt;
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Table responsive striped>
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            属性名
                                                        </td>
                                                        <td>
                                                            属性値
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>{tag.map((attr,idx)=>
                                                    <tr key={idx}>
                                                        <td>
                                                            <Form.Control type="text" value={attr['attribute']} placeholder="属性名" data-index={index} data-idx={idx} data-type="attribute" onChange={changeHeaderMeta} />
                                                        </td>
                                                        <td>
                                                            <Form.Control type="text" value={attr['value']} placeholder="属性値" data-index={index} data-idx={idx} data-type="value" onChange={changeHeaderMeta} />
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                            <Button variant="success" onClick={addHeaderMetaAttr} data-index={index}>追加</Button>
                                        </Accordion.Body>
                                    </Accordion.Item>)}
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                リンク
                            </Accordion.Header>
                            <Accordion.Body>
                                <Button variant="success" onClick={addHeaderLink} className="mb-2">追加</Button>
                                <Accordion defaultActiveKey={0}>{HeaderLink.map((tag,index)=>
                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header>
                                            &lt;link {tag.map(attr=>attr['attribute'] && `${attr['attribute']}="${attr['value']}" `)}/&gt;
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Table responsive striped>
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            属性名
                                                        </td>
                                                        <td>
                                                            属性値
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>{tag.map((attr,idx)=>
                                                    <tr key={idx}>
                                                        <td>
                                                            <Form.Control type="text" value={attr['attribute']} placeholder="属性名" data-index={index} data-idx={idx} data-type="attribute" onChange={changeHeaderLink} />
                                                        </td>
                                                        <td>
                                                            <Form.Control type="text" value={attr['value']} placeholder="属性値" data-index={index} data-idx={idx} data-type="value" onChange={changeHeaderLink} />
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                            <Button variant="success" onClick={addHeaderLinkAttr} data-index={index}>追加</Button>
                                        </Accordion.Body>
                                    </Accordion.Item>)}
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                スクリプト
                            </Accordion.Header>
                            <Accordion.Body>
                                <Button variant="success" onClick={addHeaderScript} className="mb-2">追加</Button>
                                <Accordion defaultActiveKey={0}>{HeaderScript.map((tag,index)=>
                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header>
                                            &lt;script {tag.map(attr=>attr['attribute'] && `${attr['attribute']}="${attr['value']}" `)}/&gt;
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Table responsive striped>
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            属性名
                                                        </td>
                                                        <td>
                                                            属性値
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>{tag.map((attr,idx)=>
                                                    <tr key={idx}>
                                                        <td>
                                                            <Form.Control type="text" value={attr['attribute']} placeholder="属性名" data-index={index} data-idx={idx} data-type="attribute" onChange={changeHeaderScript} />
                                                        </td>
                                                        <td>
                                                            <Form.Control type="text" value={attr['value']} placeholder="属性値" data-index={index} data-idx={idx} data-type="value" onChange={changeHeaderScript} />
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                            <Button variant="success" onClick={addHeaderScriptAttr} data-index={index}>追加</Button>
                                        </Accordion.Body>
                                    </Accordion.Item>)}
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <div className="my-2">
                    <h4>
                        フッター
                    </h4>
                    <Form.Group>
                        <Form.Label>
                            コピーライト
                        </Form.Label>
                        <Form.Control type="text" defaultValue={Item['footers']['copyrights']['text']} placeholder={"© "+date.getFullYear()+" CanTeach."} ref={CopyRight} />
                        <Form.Check type="checkbox" id="CopyRightVisible" label="コピーライトを表示する" defaultChecked={Item['footers']['copyrights']['visible']} ref={CopyRightVisible} />
                    </Form.Group>
                    <Accordion defaultActiveKey={0}>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                スクリプト
                            </Accordion.Header>
                            <Accordion.Body>
                                <Button variant="success" onClick={addFooterScript} className="mb-2">追加</Button>
                                <Accordion defaultActiveKey={0}>{FooterScript.map((tag,index)=>
                                    <Accordion.Item eventKey={index} key={index}>
                                        <Accordion.Header>
                                            &lt;script {tag.map(attr=>attr['attribute'] && `${attr['attribute']}="${attr['value']}" `)}/&gt;
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <Table responsive striped>
                                                <thead>
                                                    <tr>
                                                        <td>
                                                            属性名
                                                        </td>
                                                        <td>
                                                            属性値
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>{tag.map((attr,idx)=>
                                                    <tr key={idx}>
                                                        <td>
                                                            <Form.Control type="text" value={attr['attribute']} placeholder="属性名" data-index={index} data-idx={idx} data-type="attribute" onChange={changeFooterScript} />
                                                        </td>
                                                        <td>
                                                            <Form.Control type="text" value={attr['value']} placeholder="属性値" data-index={index} data-idx={idx} data-type="value" onChange={changeFooterScript} />
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                            <Button variant="success" onClick={addFooterScriptAttr} data-index={index}>追加</Button>
                                        </Accordion.Body>
                                    </Accordion.Item>)}
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
                <Button variant="primary" type="button" onClick={doEntry}>登録</Button>
            </div>:""}
        </div>
    );
}