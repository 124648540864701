import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createKey, Domain } from './controller/keys';
import Fetch from './controller/Fetch';

const root=document.getElementById('root');
let ifm=document.createElement('iframe');
ifm.src=Domain('login','get/');
ifm.style.display="none";
root.appendChild(ifm);
let AccessToken={
	LongToken:"",
	ShortToken:"",
	AccessToken:""
};
let rights={
	web:false,
	report:false,
	test:false,
	serma:false
};
let user={
	UserId:"",
	UserName:"",
	UserType:1,
	UserRight:0
}
let courses={
	"HavingCourse": 0,
    "MaxCourse": 0,
    "MaxStudent": 0,
};
ifm.addEventListener('load',function(){
	let nonce=createKey();
	ifm.contentWindow.postMessage({type:'GetSessions',nonce:nonce},Domain('login'));
	window.addEventListener('message',function(event){
		if(event.data.type && event.data.type=='GetSessions' && event.data.nonce===nonce){
			if(event.data.result){
				AccessToken=event.data['access_token'];
				rights=event.data['rights'];
				user=event.data['user'];
				if(user['UserType']<2){
					let send={
						url:"",
						method:'GET'
					}
					Fetch(send).then(data=>{
						//ログイン済み
						if(data['result'] && data['auth']){
							ReactDOM.render(
								<App />,
							root
							);
						}else{
							//未ログイン
							window.location.href=Domain('login','?redirect_url='+encodeURI(this.window.location.href));
						}
					})
				}else{
					window.location.href=Domain('home');
				}
			}else{
				//未ログイン
				window.location.href=Domain('login','?redirect_url='+encodeURI(this.window.location.href));
			}
		}
	},false);
},false);

export function setCourses(payloads){
	courses=Object.assign(courses,payloads);
}
export function getCourses(){
	return courses;
}
export function getAccessToken(){
	return AccessToken;
}
export function getUser(){
	return user;
}
export function getRights(){
	return rights;
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
