import { useEffect, useState } from "react";
import Fetch from "../controller/Fetch";
import AlertBox from "../controller/AlertBox";
import Loading from "../controller/Loading";
import CardList from "../controller/CardList";
import { Link, useNavigate } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";

const setting=[
    {
        index:0,
        href:"/setting",
        text:'設定'
    }
];
export default function Home(){
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();

    const navigate=useNavigate();

    useEffect(()=>{
        let send={
            url:'v1/lp/edit',
            method:'GET'
        }
        Fetch(send).then(data=>{
            if(data['result']){
                let items=[];
                for(let i=0;i<data['payloads']['pages'].length;++i){
                    items.push({
                        'index':i,
                        'href':'/page/'+data['payloads']['pages'][i]['uri'],
                        'text':data['payloads']['pages'][i]['title']
                    })
                }
                setItem({
                    url:data['payloads']['url'],
                    pages:items
                });
            }else{
                //登録ページ
                navigate('/init',{replace:true})
            }
            setVisible(false);
        }).catch(e=>{
            console.log(e)
            setVisible(false);
            setAlert({type:'danger',msgs:["通信エラーが発生しました。"]})
        })
    },[])
    function focus(e){
        e.target.select();
    }
    return (
        <div className="mt-3">
            {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Item?
            <div>
                <Form.Group>
                    <Form.Label>
                        URL
                    </Form.Label>
                    <Form.Control type="url" value={Item['url']} onFocus={focus} readOnly />
                </Form.Group>
                <Row className="w-100 mt-3">
                    <Col sm="6">
                        <CardList title="ページ" items={Item['pages']} />
                        <Link to="/page/new">新規ページ</Link>
                    </Col>
                    <Col sm="6">
                        <CardList title="設定" items={setting} />
                    </Col>
                </Row>
            </div>:""}
        </div>
    );
}