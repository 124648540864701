import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import Fetch from "../../controller/Fetch";
import AlertBox from "../../controller/AlertBox";
import Loading from "../../controller/Loading";
import Crumb from "../../controller/Crumb";
import SendJson from "../../controller/SendJson";
import { useNavigate } from "react-router-dom";

export default function Init(){
    const [Visible,setVisible]=useState(true);
    const [Item,setItem]=useState();
    const [Alert,setAlert]=useState();

    const navigate=useNavigate();

    function doEntry(){
        setVisible(true);
        const {error,body}=SendJson(document.getElementsByClassName('js-data'),true);
        if(error.length){
            setVisible(false);
            setAlert({'type':'danger','style':true,'msgs':error});
            return;
        }else{
            let send={
                url:'/v1/lp/init',
                method:'POST',
                body:body
            }
            Fetch(send).then(data=>{
                navigate('/',{replace:true});
                if(!data['result']){
                    setAlert({'type':'danger','style':true,'description':data['error']['description']});
                }
            }).catch(error=>{
                setVisible(false);
                setAlert({'type':'danger','style':true,'msgs':['通信エラーが発生しました。']});
                console.log(error);
            })
        }
    }
    useEffect(()=>{
        let send={
            url:"v1/lp/edit",
            method:'GET'
        };
        Fetch(send).then(data=>{
            if(data['result']){
                setItem(data);
                navigate('/',{replace:true});
            }else{
                setItem(data);
            }
            setVisible(false);
        }).catch(error=>{
            setVisible(false);
            console.log(error)
        })
    },[])
    return (
        <div>
        {Item? <Crumb CurrentText={"初期設定"} items={[{index:0,href:'/',text:'ホーム'}]} />:""}
        {Alert ? <AlertBox visible={true} msgs={Alert['msgs']} type={Alert['type']} />:""}
            <Loading visible={Visible} />{Item?
            <div>
                <h3>
                    LP利用登録
                </h3>
                <Form.Group>
                    <Form.Label>
                        ご希望のURLを入力してください。
                        ※変更不可
                    </Form.Label>
                    <InputGroup>
                        <InputGroup.Text>https://lp.canteach.jp/</InputGroup.Text>
                        <Form.Control type="text" name="uri" className="js-data" data-label="URL" placeholder="URL" required />
                        ※英字は全て小文字に変換されます。
                    </InputGroup>
                </Form.Group>
                <Button variant="primary" type="button" className="mt-2" onClick={doEntry}>登録</Button>
            </div>:""}
        </div>
    );
}